<template>
  <div class="logout-contain">
    <!-- 导航栏区域 -->
    <NavBar :title="'Thông tin'" :backColor="'#ff0000'"> </NavBar>
    <!-- 单元格 -->
    <div class="logout-main">
      <!-- <van-cell title="Thông tin cá nhân" @click="$router.push('/personal')">
        <van-icon slot="right-icon" name="arrow" />
      </van-cell> -->
      <van-cell-group v-for="(item, index) in LogUesrinfo" :key="index">
        <van-cell
          :title="i['name']"
          v-for="(i, idx) in item"
          :key="i['id']"
          :value="index === 2 ? value[idx] : ''"
          @click="goPage(i['topath'], i['id'])"
          class="current"
        >
          <van-icon slot="right-icon" name="arrow" />
        </van-cell>
      </van-cell-group>
      <van-button
        round
        block
        type="info"
        class="logot-btn"
        @click="logout"
        :class="{ 'yellow-color': !$isLinkGame, 'black-bc': !$isLinkGame }"
        >Đăng xuất</van-button
      >
    </div>
  </div>
</template>

<script>
import NavBar from '@/components/Navbar.vue'
export default {
  name: 'Logout',
  components: {
    NavBar,
  },
  data() {
    return {
      LogUesrinfo: [
        [
          { id: '0', name: 'Mật khẩu đăng nhập', topath: '/editpasword' },
          // { id: '1', name: '支付密码', topath: '/user/pay_password' }
        ],
        [
          { id: '2', name: 'Thẻ ngân hàng Của tôi', topath: '/bank' },
          // { id: '3', name: '我的收货地址' }
        ],
        // [
        //   { id: "4", name: "检测新版本" },
        //   { id: "5", name: "清除缓存分区" },
        // ],
      ],
      value: ['Current version 5.2', '34.26 MB'],
    }
  },
  mounted() {},
  created() {},
  methods: {
    // 点击退出事件
    logout() {
      // 1.1、确认退出的弹出框
      this.$dialog
        .confirm({
          title: 'Đăng xuất',
          message: 'Bạn có muốn đăng xuất?',
          cancelButtonText: 'Hủy bỏ',
          confirmButtonText: 'xác nhận',
          confirmButtonColor: '#00aa88',
        })
        .then(() => {
          this.$store.commit('delteUer')
          this.$router.push('/')
          window.location.reload()
          window.localStorage.clear()
        })
        .catch(() => {
          // on cancel
        })
    },

    // 点击每一หนึ่งสถานที่单元格触发的事件
    goPage(topath, id) {
      if (topath) {
        this.$router.push(topath)
      }
    },
  },
}
</script>

<style lang="less" scoped>
.logout-contain {
  position: relative;

  /deep/ .van-nav-bar__title {
    // color: #fff;
  }
  /deep/ .van-nav-bar__left {
    .iconfont {
      // color: #fff;
    }
  }
  .van-cell {
    height: 100px;
    padding: 30px 20px 30px 24px;
  }
  .logout-main {
    position: absolute;
    top: 118px;
    left: 0;
    right: 0;
  }
  .van-cell-group {
    margin-top: 18px;
  }
  .van-icon {
    margin-top: 8px;
  }
  /deep/ .van-cell__title {
    font-size: 29px;
    color: #666;
  }
  /deep/ .van-cell__value {
    font-size: 12px;
    color: #d2d2d2;
  }
  .logot-btn {
    width: 616px;
    height: 90px;
    background-color: #ff0000;
    color: #fff;
    border: 0;
    font-size: 40px;
    font-family: 'PingFang-Medium';
    margin: 60px auto;
  }
  .current {
    span {
      font-size: 28px;
    }
  }
}
</style>
